import { AppProps } from "next/app";
import { AppCacheProvider } from "@mui/material-nextjs/v13-pagesRouter";
import ThemeProvider from "@/theme/ThemeProvider";
import { NextPage } from "next";
import { Provider } from "react-redux";
import { store } from "@/redux";
import React from "react";
import Script from "next/script";
import "react-day-picker";

interface MyAppProps extends AppProps {
  Component: NextPage & {
    getLayout?: (page: React.ReactNode) => React.ReactNode;
  };
}

export default function App(props: MyAppProps) {
  const { Component, pageProps } = props;
  const { getLayout = (page: React.ReactNode) => page } = Component;
  return (
    <>
      <title>hot.meme — spice level: cooking</title>
      <AppCacheProvider {...props}>
        <Provider store={store}>
          {getLayout ? (
            getLayout(<Component {...pageProps} />)
          ) : (
            <Component {...pageProps} />
          )}
        </Provider>
      </AppCacheProvider>

      {/* <Script
        strategy="lazyOnload"
        src="//at.alicdn.com/t/c/font_4187298_rcac8f84va8.js"
      /> */}
      <Script
        strategy="lazyOnload"
        src="//at.alicdn.com/t/c/font_4497786_x1jobs8hnm.js"
      />

      <Script
        strategy="lazyOnload"
        src="https://www.googletagmanager.com/gtag/js?id=G-6K5Y7116KL"
      />

      <Script strategy="lazyOnload" id="gtag">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());

          gtag('config', 'G-6K5Y7116KL');
        `}
      </Script>
    </>
  );
}
