import { createSlice } from "@reduxjs/toolkit";
import { GetBlockinfo } from "@/types/btc";
export interface INetRate {
  economyFee: number; // 无优先级
  fastestFee: number; // 高优先级
  halfHourFee: number; // 中优先级
  hourFee: number; // 低优先级
  minimumFee: number;
}
// import Big from 'big.js';
interface ConfigState {
  toggleRefresh: boolean;
  usdRate: number; // btc 对 usd 汇率
  netRate: INetRate;
  netHourRate: number; //小时网络费
  isPollingUpdate: boolean;
  pollingNum: number;
  versions: "v1" | "v2";
  alertIndex: number | string; //   用来控制弹出哪个弹窗
  showTimeOut: boolean; // 控制安全保障弹窗是否显示倒计时
  chain: "btc" | "doge" | "ethereum" | undefined;
  runesConfig?: GetBlockinfo["res"]["data"];
}

const initialState: ConfigState = {
  toggleRefresh: false,
  netRate: {
    fastestFee: 0,
    halfHourFee: 0,
    hourFee: 0,
    economyFee: 0,
    minimumFee: 0,
  } as INetRate,
  usdRate: 0,
  netHourRate: 0,

  // 用于循环调用费率
  isPollingUpdate: false,
  pollingNum: 0,
  versions: "v1",

  alertIndex: 0,
  showTimeOut: false,
  chain: undefined,
  runesConfig: undefined,
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    doToggleRefresh: (state) => {
      state.toggleRefresh = !state.toggleRefresh;
    },
    setRate: (state, action) => {
      state.usdRate = action.payload;
    },
    setNetRate: (state, action) => {
      if (!action.payload.fastestFee) {
        return;
      }
      state.netRate = action.payload;
      // 这个为什么要这样设置？
      const { hourFee = 0 } = action.payload;
      state.netHourRate = hourFee;
    },
    openPollingUpdate: (state) => {
      if (!state.isPollingUpdate) state.isPollingUpdate = true;
      state.pollingNum++;
    },
    cancelPolling: (state) => {
      const resNum = state.pollingNum - 1;
      state.pollingNum = resNum;
      if (resNum <= 0) {
        state.isPollingUpdate = false;
      }
    },
    doSetVersions: (state, action) => {
      state.versions = action.payload;
    },
    changeAlertIndex: (state, action) => {
      state.alertIndex = action.payload;
    },
    resetAlertIndex: (state) => {
      state.alertIndex = 0;
    },
    changeShowTimeOut: (state, action) => {
      state.showTimeOut = action.payload;
    },
    setChain: (
      state,
      action: {
        payload: ConfigState["chain"];
      },
    ) => {
      state.chain = action.payload;
    },
    changeRunesConfig: (state, action) => {
      state.runesConfig = action.payload;
    },
  },
});

// 为每个 case reducer 函数生成 Action creators
export const {
  setRate,
  setNetRate,
  doToggleRefresh,
  openPollingUpdate,
  cancelPolling,
  doSetVersions,
  changeAlertIndex,
  resetAlertIndex,
  changeShowTimeOut,
  setChain,
  changeRunesConfig,
} = configSlice.actions;

export default configSlice.reducer;
